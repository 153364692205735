import React from "react"
import FancyHeader from "./elements/FancyHeader"

const Services = ({ children }) => {

    const notaryItems = [
        "All notarizations - financial, legal, business documents and more",
        "We can come to your location"
    ]

    const saItems = [
        "All Closings Documents - loan mortgage, loan refinance, cash deal and more",
        "Borrower will be walked through the loan, loan documents will be executed, notarized, and returned for processing on time"
    ]

    const icon = _ => (
        <svg className="flex-shrink-0 w-6 h-6 mr-1 text-green-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
        </svg>
    )

    return (
        <div className="w-full min-h-full p-8 mx-auto max-w-7xl">
            <FancyHeader>
                Services
            </FancyHeader>
            <div className="mb-6 text-xl font-normal text-center text-gray-700">
                Insured & Bonded
            </div>
            <div className="flex flex-col text-lg md:flex-row">
                <div className="mb-5 md:w-1/2 md:mb-0">
                    <h3 className="mb-4 text-2xl font-semibold">Mobile Notary</h3>
                    <ul>
                        {notaryItems.map(item => (
                            <li className="flex flex-row mb-4">
                                {icon()}
                                <span>{item}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="md:w-1/2">
                    <h3 className="mb-4 text-2xl font-semibold">Signing Agent</h3>
                    <ul>
                        {saItems.map(item => (
                            <li className="flex flex-row mb-4">
                                {icon()}
                                <span>{item}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Services