import React from "react"
import FancyHeader from './elements/FancyHeader'

const About = ({ children }) => {

    const text = "We provide loan signing and mobile notary services to clients in the Central Florida area. We specialize in loan refinances, loan mortgages and all types of closings. We are a licensed and insured company, ensuring all of our agents are experienced in the real estate field. All our agents are Fluent in both English and in the Spanish language vocabulary, both orally and in writing."
    const text2 = "We make the loan signing experience convenient, easy, and joyful for all parties involved. Listening to our clients' needs and expectations set our services apart from other signing agents."

    // this is a test for dev
    return (
        <div className="flex flex-col w-full py-8 mx-auto bg-gray-100 sm:flex-row max-w-7xl">

            <div className="hidden h-full m-8 md:w-1/2 md:block">
                <img className="w-full h-full" src="/about.jpg" alt="About Amanda - Mobile Notary & SA" />
            </div>

            <div className="h-full m-8 text-lg md:w-1/2">
                <FancyHeader>
                    Get To Know Us
                </FancyHeader>

                <div className="h-full my-8 md:hidden">
                    <img className="w-full h-full" src="/about.jpg" alt="About Amanda - Mobile Notary & SA" />
                </div>

                <p className="mb-4">{text}</p>
                <p className="mb-4">{text2}</p>
            </div>

        </div>
    )
}

export default About