import React from "react"
import SEO from '../components/SEO'
import About from "../components/About"
import Footer from "../components/Footer"
import Landing from "../components/Landing"
import Services from "../components/Services"
import Contact from '../components/Contact'

const Index = () => {
  return (
    <>
      <SEO />

      <div className="flex flex-col min-h-screen">

        <Landing />

        <About />

        <Services />

        <Contact />

        <Footer />

      </div>

    </>
  )
}

export default Index