import React from "react"

const FancyHeader = ({ children }) => {
    return (
        <div className="mb-4">
            <h2 className="text-4xl font-normal text-center">
                {children}
            </h2>
            <div className="h-0.5 bg-yellow-500 max-w-xs mx-auto mt-4"></div>
        </div>
    )
}

export default FancyHeader