import React from "react"

const Landing = ({ children }) => {
    return (
        <div className="relative sm:overflow-hidden">
            <div className="absolute inset-0">
                <img className="object-fill w-full h-full" src="/landing.jpg" alt="My Notary SA, LLC - computer desk" />
                <div className="absolute inset-0 bg-white opacity-40" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="max-w-xl mx-auto">
                    <img src="/my-notary-sa-logo.png" alt="My Notary SA, LLC - logo" />
                </h1>
                <div className="h-0.5 bg-yellow-500 max-w-xs mx-auto mt-4"></div>
                <p className="max-w-lg mx-auto mt-4 text-xl text-center text-gray-600 md:text-2xl sm:max-w-3xl">
                    Signing Agents & Business Services
                    </p>
            </div>
        </div >
    )
}

export default Landing