import React from 'react'
import FancyHeader from './elements/FancyHeader'


const Contact = () => {
    return (
        <div className="w-full min-h-full py-8 mx-auto max-w-7xl">

            <div className="p-6 bg-gray-100">

                <FancyHeader>
                    Get in touch
                </FancyHeader>

                <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-1/2">
                        <div className="flex items-center mt-8">
                            <svg className="flex-shrink-0 w-6 h-6 mr-1 text-yellow-500" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                            <div className="tracking-wide text-md">
                                4401 E. Colonial Dr. <br /> Suite 204F, Orlando, FL 32803
                    </div>
                        </div>

                        <div className="flex items-center mt-4">
                            <svg className="flex-shrink-0 w-6 h-6 mr-1 text-yellow-500" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                            </svg>
                            <div className="tracking-wide text-md">
                                <a href="tel:1-407-694-0451" className="lg:pointer-events-none">+1-407-694-0451</a>
                            </div>
                        </div>

                        <div className="flex items-center mt-2">
                            <svg className="flex-shrink-0 w-6 h-6 mr-1 text-yellow-500" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                            <div className="racking-wide text-md">
                                <a href="mailto:amanda@mynotarysa.com">amanda@mynotarysa.com</a>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center w-full mt-4 md:justify-end md: md:w-1/2 md:flex-row md:mt-0">
                        <img className="w-32 h-32 my-2 " src="/nna.png" alt="" />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Contact